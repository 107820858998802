export const theme = {
  colors: {
    gray: '#ADB5BD',
    gray100: '#DEE2E6',
    gray500: '#ADB5BD',
    gray700: '#495057',
    darkGray: '#242424',
    black: '#161616',
    bronze: '#B7986A',
    white: '#FFFFFF',
    backgroundGray: '#DCDCDA',
    backgroundGraySecondary: '#EFEFEF',
    blackLight: '#1E1E1E',
    link: '#B7986A',
    blue: '#0046BE',
    blue200: '#0085FF',
    lightBlue: '#02BFF1',
    red: '#E1443A',
  },
  breakpoints: {
    xSmall: '320px',
    small: '576px',
    medium: '1023px',
    large: '1439px',
    xLarge: '1900px',
  },
  spacing: {
    xxxs: '4px',
    xxs: '8px',
    xs: '16px',
    s: '24px',
    m: '32px',
    l: '40px',
    xl: '56px',
    xxl: '64px',
    xxxl: '80px',
    xxxxl: '120px',
    xxxxxl: '184px',
    xxxxxxl: '240px',
  },
};
